import React from 'react';
import pt from 'prop-types';
import Container from '../../../../../modules/container';
import { combine } from '../../../../../helpers/styles';
import RichText, { richTextPropTypes } from '../../../../ui/richText';
import CustomScrollable from '../../../../ui/customScrollable';
import Link, { linkProptypes } from '../../../../ui/link';
import * as styles from './styles.module.scss';
import Headline, { headlinePropTypes } from '../../../../ui/headline';
import TeaserItem, { teaserItemProptypes } from '../teaserItem';

const SliderTeaser = ({
  headline,
  text,
  link,
  teaserItems,
  beigeBackground,
  spacing,
  mobileLayout,
}) => {
  const ContentContainer = ({ children }) => {
    if (!mobileLayout || mobileLayout === 'Slider') {
      return (
        <CustomScrollable
          vertical={false}
          onlySmall={false}
          className={styles.scrollContainerSm}
        >
          {children}
        </CustomScrollable>
      );
    }

    return children;
  };

  return (
    <div
      className={combine(
        styles.teaserWrapper,
        styles.sliderVersion,
        beigeBackground && styles.beigeBackground,
        spacing === 'Big' && styles.bigSpacing
      )}
    >
      <Container className={styles.sliderContainer}>
        <CustomScrollable
          vertical={false}
          onlySmall={false}
          className={styles.scrollContainer}
        >
          <div className={styles.wrapperheader}>
            {headline && (
              <Headline
                text={headline}
                level="span"
                type="h1"
                className={styles.headline}
              />
            )}
            {text && (
              <RichText
                content={text}
                paragraphAs="span"
                paragraphType="p1"
                className={styles.text}
              />
            )}
            {link && (
              <Link link={link} asButton className={styles.cta}>
                {link.text}
              </Link>
            )}
          </div>
          <div className={styles.innerContainer}>
            {teaserItems?.map((item, index) => (
              <TeaserItem
                headline={item.headline}
                text={item.text}
                image={item.image}
                video={item.video}
                link={item.link}
                badgeText={item.badgeText}
                badgeYear={item.badgeYear}
                badgeIcon={item.badgeIcon}
                hideArrowAndText={item.hideArrowAndText}
                blackText={beigeBackground}
                key={index}
                className={styles.teaserItem}
              />
            ))}
          </div>
        </CustomScrollable>
        <ContentContainer>
          <div
            className={combine(styles.innerContainerSm, styles.grid)}
            style={{ '--columns': mobileLayout === '1 Column' ? 1 : 2 }}
          >
            {teaserItems?.map((item, index) => (
              <TeaserItem
                headline={item.headline}
                text={item.text}
                image={item.image}
                video={item.video}
                link={item.link}
                badgeText={item.badgeText}
                badgeYear={item.badgeYear}
                badgeIcon={item.badgeIcon}
                hideArrowAndText={item.hideArrowAndText}
                blackText={beigeBackground}
                key={index}
                className={combine(
                  mobileLayout &&
                    mobileLayout !== 'Slider' &&
                    styles.teaserItemSm
                )}
                mediaClassName={combine(
                  mobileLayout && mobileLayout !== 'Slider' && styles.w100
                )}
              />
            ))}
          </div>
        </ContentContainer>
      </Container>
    </div>
  );
};

SliderTeaser.propTypes = {
  teaserItems: pt.arrayOf(pt.shape(teaserItemProptypes)).isRequired,
  headline: pt.shape(headlinePropTypes),
  text: pt.shape(richTextPropTypes),
  link: pt.shape(linkProptypes),
  beigeBackground: pt.bool,
};

SliderTeaser.defaultProps = {
  headline: '',
  link: null,
  text: null,
  beigeBackground: false,
};

export default SliderTeaser;
