import React from 'react';
import pt from 'prop-types';
import Text from '../../../../ui/text';
import Link, { linkProptypes } from '../../../../ui/link';
import Image, { imageProptypes } from '../../../../ui/image';
import Video, { videoPropTypes } from '../../../../ui/video';
import { combine } from '/src/helpers/styles';
import RichText, { richTextPropTypes } from '../../../../ui/richText';
import TeaserBadge from '../teaserBadge';
import Icon from '../../../../ui/icon';
import * as styles from './styles.module.scss';
import {
  isSSRPageLink,
  SSRPageLinks,
} from '../../../../../helpers/isSSRPageLink';

const TeaserItem = ({
  headline,
  subheadline,
  text,
  badgeIcon,
  badgeYear,
  badgeText,
  image,
  video,
  hideArrowAndText,
  displayTextOnRightSide,
  blackText,
  link,
  isHorizontal,
  className,
  mediaClassName,
}) => {
  return video ? (
    <div
      className={combine(
        !isHorizontal && styles.teaser,
        isHorizontal && styles.horizontalTeaser,
        !displayTextOnRightSide && styles.reverse,
        styles.withVideo,
        className && className
      )}
    >
      <div className={combine(styles.media, mediaClassName)}>
        <Video video={video} poster={image} />
      </div>
      <div className={styles.info}>
        {headline && (
          <Text
            as="h3"
            type="h3"
            variant="semibold"
            className={styles.headline}
          >
            {headline}
          </Text>
        )}
        {text && (
          <RichText
            content={text}
            paragraphAs="span"
            paragraphType="p1"
            className={combine(styles.text, blackText && styles.black)}
          />
        )}
        {!hideArrowAndText && (
          <Link link={link} useGatsbyLink={!isSSRPageLink(SSRPageLinks, link)}>
            {Boolean(link.text.trim()) && (
              <Text as="p" type="p1" className={styles.arrowText}>
                {link.text}
              </Text>
            )}
            <Icon name="arrow-right" size="2x" className={styles.arrow} />
          </Link>
        )}
      </div>
    </div>
  ) : (
    <Link
      link={link}
      useGatsbyLink={!isSSRPageLink(SSRPageLinks, link)}
      className={combine(
        !isHorizontal && styles.teaserLink,
        className && className
      )}
    >
      <div
        className={combine(
          !isHorizontal && styles.teaser,
          isHorizontal && styles.horizontalTeaser,
          !displayTextOnRightSide && styles.reverse,
          className && className
        )}
      >
        <div className={combine(styles.media, mediaClassName)}>
          <Image
            {...image}
            disableImageCaption
            className={styles.backgroundImage}
          />
          <TeaserBadge
            badgeIcon={badgeIcon}
            badgeYear={badgeYear}
            badgeText={badgeText}
          />
        </div>
        <div className={styles.info}>
          {headline && (
            <Text
              as="h3"
              type="h3"
              variant="semibold"
              className={styles.headline}
            >
              {headline}
            </Text>
          )}
          {subheadline && (
            <Text
              as="span"
              type="p1"
              variant="light"
              className={styles.subheadline}
            >
              {subheadline}
            </Text>
          )}
          {text && (
            <RichText
              content={text}
              paragraphAs="span"
              paragraphType="p1"
              className={combine(styles.text, blackText && styles.black)}
            />
          )}
          {!hideArrowAndText && link && (
            <>
              {Boolean(link.text.trim()) && (
                <Text as="p" type="p1" className={styles.arrowText}>
                  {link.text}
                </Text>
              )}
              <Icon name="arrow-right" size="2x" className={styles.arrow} />
            </>
          )}
        </div>
      </div>
    </Link>
  );
};

export const teaserItemProptypes = {
  headline: pt.string.isRequired,
  subheadline: pt.string,
  text: pt.shape(richTextPropTypes),
  badgeIcon: pt.string,
  badgeYear: pt.number,
  badgeText: pt.string,
  image: pt.shape(imageProptypes),
  video: pt.shape(videoPropTypes),
  hideArrowAndText: pt.bool,
  blackText: pt.bool,
  displayTextOnRightSide: pt.bool,
  link: pt.shape(linkProptypes),
  isHorizontal: pt.bool,
  className: pt.string,
};

TeaserItem.propTypes = teaserItemProptypes;

TeaserItem.defaultProps = {
  subheadline: '',
  text: '',
  badgeIcon: '',
  badgeYear: 2021,
  badgeText: '',
  hideArrowAndText: false,
  blackText: false,
  image: null,
  video: null,
  link: null,
  displayTextOnRightSide: true,
  isHorizontal: false,
  className: '',
};

export default TeaserItem;
