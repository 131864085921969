import React from 'react';
import pt from 'prop-types';
import Image from '../../../ui/image';
import { combine } from '../../../../helpers/styles';
import Text from '../../../ui/text';
import Link from '../../../ui/link';
import Icon from '../../../ui/icon';
import Container from '../../../../modules/container';
import * as styles from './styles.module.scss';

const image = {
  url: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
};

const CountryAtAGlance = ({}) => (
  <Container fullWidth className={styles.container}>
    <Container small>
      <Text as="span" type="infotext" className={styles.title}>
        At a glance
      </Text>
      <div className={styles.wrapper}>
        <Image
          image={image}
          className={styles.item}
          imgClassName={styles.image}
        />
        <div className={combine(styles.item, styles.red)}>
          <Link link={{ externalLink: '//google.com' }} className={styles.link}>
            <Text
              as="span"
              type="h2"
              variant="semibold"
              className={styles.itemTitle}
            >
              4875
            </Text>
            <Text as="span" type="button">
              iF AWARDS
            </Text>
            <Icon size="2x" name="arrow-right" className={styles.icon} />
          </Link>
        </div>
        <div className={combine(styles.item, styles.white, styles.double)}>
          <Text as="span" type="infotext" className={styles.label}>
            sectors in china
          </Text>
          <Text
            as="span"
            type="h2"
            variant="semibold"
            className={styles.itemTitle}
          >
            KITCHEN
          </Text>
          <Text as="span" type="h2">
            Packaging
          </Text>
          <Text as="span" type="h2">
            Telecoms
          </Text>
        </div>
        <div className={combine(styles.item, styles.white)}>
          <Text
            as="span"
            type="h2"
            variant="semibold"
            className={styles.itemTitle}
          >
            1013
          </Text>
          <Text as="span" type="metanavi" variant="upper">
            Companies
          </Text>
        </div>
        <div className={combine(styles.item, styles.white)}>
          <Text
            as="span"
            type="h2"
            variant="semibold"
            className={styles.itemTitle}
          >
            470
          </Text>
          <Text as="span" type="metanavi" variant="upper">
            Design & Architecture Studios
          </Text>
        </div>
        <div className={combine(styles.item, styles.gold)}>
          <Link link={{ externalLink: '//google.com' }} className={styles.link}>
            <Text
              as="span"
              type="h2"
              variant="semibold"
              className={styles.itemTitle}
            >
              71
            </Text>
            <Text as="span" type="button">
              Gold AWARDS
            </Text>
            <Icon size="2x" name="arrow-right" className={styles.icon} />
          </Link>
        </div>
        <div className={combine(styles.item, styles.white)}>
          <Text
            as="span"
            type="h2"
            variant="semibold"
            className={styles.itemTitle}
          >
            54
          </Text>
          <Text as="span" type="metanavi" variant="upper">
            Jurors
          </Text>
        </div>
      </div>
    </Container>
  </Container>
);

CountryAtAGlance.propTypes = {};

export default CountryAtAGlance;
