import React from 'react';
import pt from 'prop-types';
import CMSSeparator from '../../cms-modules/separator';
import TeaserWrapper from '../../cms-modules/teaserWrapper';
import Headline from '../../ui/headline';
import CountryAtAGlance from '../components/countryAtAGlance';
import CountryTabs from '../components/countryTabs';
import DynamicTeaser from '../../cms-modules/dynamicTeaser';
import * as styles from './styles.module.scss';

const CountryProfileDetails = ({}) => {
  return (
    <>
      <Headline
        text="China"
        level="h1"
        type="h1"
        variant="semibold"
        className={styles.header}
      />
      <CountryAtAGlance />
      <CountryTabs />

      <DynamicTeaser
        headline="<b>iF Awards</b> in China"
        type="Specific Projects Grid"
        dataIds={[]}
        beigeBackground
        link={{ linkToPage: '/' }}
      />

      <TeaserWrapper
        type="Columns"
        headline="<b>Student Concepts</b> FROM CHINA"
        teaserItems={[]}
      />
      <TeaserWrapper
        type="Columns"
        headline="<b>Social Projects</b> FROM CHINA"
        teaserItems={[]}
      />

      <CMSSeparator
        title={{ headline: 'Upcoming\nevents\nin china' }}
        ctas={[
          {
            externalLink: '//google.com',
            text: 'EXPLORE NOW',
            transparent: true,
            type: 'secondary',
          },
        ]}
      />

      <DynamicTeaser
        headline="Chinese <b>Companies &amp; Studios</b>"
        type="Specific Projects Grid"
        dataIds={[]}
        beigeBackground
      />

      <TeaserWrapper
        type="Columns"
        headline="<b>iF Jurors</b> FROM CHINA"
        teaserItems={[]}
      />
    </>
  );
};

CountryProfileDetails.propTypes = {};

export default CountryProfileDetails;
