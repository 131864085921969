import React from 'react';
import pt from 'prop-types';
import CustomTabs from '../../../ui/customTabs';
import CountryTabItem from './components/rankingTabPanel';
import * as styles from './styles.module.scss';

// dummy
const rankings = [
  {
    category: 'Company',
    companyName: 'Midea Group',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    companyName: 'Haier Group',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    companyName: 'Samsung Electronics',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    companyName: 'Lenovo',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    companyName: 'Huawei Technologies',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    companyName: 'Xiaomi',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    companyName: 'Profile Name Lorem',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    companyName: 'Profile Name Lorem',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    companyName: 'Profile Name Lorem',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    companyName: 'Profile Name Lorem',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
];

const sectors = [
  {
    category: 'Company',
    sectorName: 'Midea Group',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    sectorName: 'Haier Group',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    sectorName: 'Samsung Electronics',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    sectorName: 'Lenovo',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    sectorName: 'Huawei Technologies',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    sectorName: 'Xiaomi',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    sectorName: 'Profile Name Lorem',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    sectorName: 'Profile Name Lorem',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    sectorName: 'Profile Name Lorem',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
  {
    category: 'Company',
    sectorName: 'Profile Name Lorem',
    link: '//google.com',
    logo: '//images.ctfassets.net/9el74s703hin/4uj5TRrpvSHxizoqLsEEnR/32bfb620b47d13928c78a2a1bea1e004/Bildschirmfoto_2021-08-08_um_13.52.07.png?w=424&h=478&q=50&fm=png',
  },
];

const tabList = [
  'Best companies',
  'Best design & Architecture studios',
  'Best inhouse design teams',
  'Sectors',
];
const tabPanels = [
  <CountryTabItem items={rankings} headline="<b>Best companies</b> in China" />,
  <CountryTabItem
    items={rankings}
    headline="<b>Best design &amp; Architecture studios</b> in China"
  />,
  <CountryTabItem
    items={rankings}
    headline="<b>Best inhouse design teams</b> in China"
  />,
  <CountryTabItem
    items={sectors}
    headline="<b>Best sectors</b> in China"
    type="sectors"
  />,
];

const CountryTabs = ({}) => {
  return (
    <div className={styles.container}>
      <CustomTabs
        tabList={tabList}
        tabPanels={tabPanels}
        tabListClassName={styles.tabList}
      />
    </div>
  );
};

CountryTabs.propTypes = {};

export default CountryTabs;
