// extracted by mini-css-extract-plugin
export var beigeBackground = "styles-module--beigeBackground--895a7";
export var bigSpacing = "styles-module--bigSpacing--49ab2";
export var fitContent = "styles-module--fitContent--ea1c2";
export var grid = "styles-module--grid--8e3ee";
export var headline = "styles-module--headline--3107b";
export var horizontalTeaser = "styles-module--horizontalTeaser--d356e";
export var innerContainer = "styles-module--innerContainer--808dd";
export var scrollContainer = "styles-module--scrollContainer--46e7d";
export var teaserItemSm = "styles-module--teaserItemSm--8b648";
export var teaserWrapper = "styles-module--teaserWrapper--d8605";
export var w100 = "styles-module--w100--8a992";
export var withPadding = "styles-module--withPadding--4108b";
export var wrapperheader = "styles-module--wrapperheader--0e379";