// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--9ad1c";
export var arrowText = "styles-module--arrowText--7abc2";
export var backgroundImage = "styles-module--backgroundImage--f06b4";
export var black = "styles-module--black--aa1cc";
export var headline = "styles-module--headline--5fdf7";
export var horizontalTeaser = "styles-module--horizontalTeaser--f2033";
export var info = "styles-module--info--e5549";
export var media = "styles-module--media--2dd32";
export var reverse = "styles-module--reverse--d1536";
export var subheadline = "styles-module--subheadline--81992";
export var teaser = "styles-module--teaser--bddc1";
export var teaserLink = "styles-module--teaserLink--bbcb9";
export var text = "styles-module--text--b38f6";
export var withVideo = "styles-module--withVideo--45fe7";