import React from 'react';
import pt from 'prop-types';
import ColumnTeaser from './components/columnTeaser';
import SliderTeaser from './components/sliderTeaser';

const TeaserWrapper = (props) => {
  const { type } = props;

  return type === 'Columns' ? (
    <ColumnTeaser {...props} />
  ) : (
    <SliderTeaser {...props} />
  );
};

TeaserWrapper.propTypes = {
  type: pt.oneOf(['Columns', 'Slider']).isRequired,
};

TeaserWrapper.defaultProps = {
  type: 'Columns',
};

export default TeaserWrapper;
