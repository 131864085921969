import React from 'react';
import pt from 'prop-types';
import { combine } from '../../../../../helpers/styles';
import AwardBadge from '../../../../ui/awardBadge';
import Text from '../../../../ui/text';
import * as styles from './styles.module.scss';

const TeaserBadge = ({ badgeIcon, badgeText, badgeYear }) => {
  if (badgeIcon) {
    return (
      <AwardBadge
        name={badgeIcon}
        year={badgeYear}
        className={combine(styles.badge, styles.badgeIcon)}
        height={80}
      />
    );
  }

  if (badgeText) {
    return (
      <span className={combine(styles.badge, styles.badgeText)}>
        <Text as="span" type="infotext">
          {badgeText}
        </Text>
      </span>
    );
  }

  return null;
};

TeaserBadge.propTypes = {
  badgeIcon: pt.string,
  badgeText: pt.string,
  badgeYear: pt.number,
};

TeaserBadge.defaultProps = {
  badgeIcon: null,
  badgeText: null,
  badgeYear: 2021,
};

export default TeaserBadge;
