// extracted by mini-css-extract-plugin
export var container = "styles-module--container--64f29";
export var double = "styles-module--double--3903e";
export var gold = "styles-module--gold--02bb3";
export var icon = "styles-module--icon--29d70";
export var image = "styles-module--image--c53b5";
export var item = "styles-module--item--3fe21";
export var itemTitle = "styles-module--itemTitle--d5dd3";
export var label = "styles-module--label--1ee44";
export var link = "styles-module--link--a08d4";
export var red = "styles-module--red--ffcad";
export var title = "styles-module--title--8d295";
export var white = "styles-module--white--c30dc";
export var wrapper = "styles-module--wrapper--75b30";