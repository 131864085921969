import React from 'react';
import Layout from '../modules/layout';
import CountryProfileDetails from '../components/wdi-ranking/country-profile';

const CountryProfile = () => {
  return null;

  // DEACTIVATE PAGE AS IT IS CURRENTLY NOT USED

  return (
    <Layout title="Country Profile" description="Country Profile">
      <CountryProfileDetails />
    </Layout>
  );
};

export default CountryProfile;
