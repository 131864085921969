import pt from 'prop-types';
import React from 'react';
import { combine } from '../../../../../helpers/styles';
import Container from '../../../../../modules/container';
import CustomScrollable from '../../../../ui/customScrollable';
import Headline, { headlinePropTypes } from '../../../../ui/headline';
import Link, { linkProptypes } from '../../../../ui/link';
import TeaserItem, { teaserItemProptypes } from '../teaserItem';
import * as styles from './styles.module.scss';

const ColumnTeaser = ({
  headline,
  link,
  teaserItems,
  displayTextOnRightSide,
  beigeBackground,
  spacing,
  mobileLayout,
}) => {
  const ContentContainer = ({ children }) => {
    if (!mobileLayout || mobileLayout === 'Slider') {
      return (
        <CustomScrollable
          vertical={false}
          onlySmall={true}
          className={styles.scrollContainer}
        >
          {children}
        </CustomScrollable>
      );
    }

    return (
      <div
        className={styles.grid}
        style={{ '--columns': mobileLayout === '1 Column' ? 1 : 2 }}
      >
        {children}
      </div>
    );
  };

  return (
    <div
      className={combine(
        styles.teaserWrapper,
        beigeBackground && styles.beigeBackground,
        spacing === 'Big' && styles.bigSpacing,
        teaserItems?.length === 1 && styles.horizontalTeaser
      )}
    >
      <Container>
        <div
          className={combine(
            headline && styles.wrapperheader,
            beigeBackground && teaserItems?.length !== 1 && styles.withPadding
          )}
        >
          {headline && (
            <Headline
              text={headline}
              level="span"
              type="h1"
              className={styles.headline}
            />
          )}
          {link && (
            <Link link={link} asButton className={styles.fitContent}>
              {link.text}
            </Link>
          )}
        </div>
        <div className={styles.innerContainer}>
          {teaserItems && teaserItems.length === 1 ? (
            teaserItems.map((item, index) => (
              <TeaserItem
                isHorizontal
                headline={item.headline}
                text={item.text}
                image={item.image}
                video={item.video}
                link={item.link}
                badgeText={item.badgeText}
                badgeYear={item.badgeYear}
                badgeIcon={item.badgeIcon}
                hideArrowAndText={item.hideArrowAndText}
                displayTextOnRightSide={displayTextOnRightSide}
                blackText={beigeBackground}
                key={`${item.id}-${index}`}
              />
            ))
          ) : (
            <ContentContainer>
              {teaserItems?.map((item, index) => (
                <TeaserItem
                  headline={item.headline}
                  text={item.text}
                  image={item.image}
                  video={item.video}
                  link={item.link}
                  badgeText={item.badgeText}
                  badgeYear={item.badgeYear}
                  badgeIcon={item.badgeIcon}
                  hideArrowAndText={item.hideArrowAndText}
                  subheadline={item.subline}
                  blackText={beigeBackground}
                  key={index}
                  className={combine(
                    mobileLayout &&
                      mobileLayout !== 'Slider' &&
                      styles.teaserItemSm
                  )}
                  mediaClassName={combine(
                    mobileLayout && mobileLayout !== 'Slider' && styles.w100
                  )}
                />
              ))}
            </ContentContainer>
          )}
        </div>
      </Container>
    </div>
  );
};

ColumnTeaser.propTypes = {
  teaserItems: pt.arrayOf(pt.shape(teaserItemProptypes)).isRequired,
  headline: pt.shape(headlinePropTypes),
  link: pt.shape(linkProptypes),
  displayTextOnRightSide: pt.bool,
  beigeBackground: pt.bool,
};

ColumnTeaser.defaultProps = {
  headline: '',
  link: null,
  displayTextOnRightSide: true,
  beigeBackground: false,
};

export default ColumnTeaser;
