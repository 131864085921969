// extracted by mini-css-extract-plugin
export var beigeBackground = "styles-module--beigeBackground--e89aa";
export var bigSpacing = "styles-module--bigSpacing--7de5d";
export var cta = "styles-module--cta--0c673";
export var grid = "styles-module--grid--d8d48";
export var headline = "styles-module--headline--b5039";
export var innerContainer = "styles-module--innerContainer--30c80";
export var innerContainerSm = "styles-module--innerContainerSm--ca42d";
export var scrollContainer = "styles-module--scrollContainer--296b4";
export var scrollContainerSm = "styles-module--scrollContainerSm--d03cf";
export var sliderContainer = "styles-module--sliderContainer--4cf64";
export var sliderVersion = "styles-module--sliderVersion--7d9d0";
export var teaserItem = "styles-module--teaserItem--65185";
export var teaserItemSm = "styles-module--teaserItemSm--14a1b";
export var teaserWrapper = "styles-module--teaserWrapper--963d2";
export var text = "styles-module--text--93bc5";
export var w100 = "styles-module--w100--e26b9";
export var wrapperheader = "styles-module--wrapperheader--307f0";